import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-3px" }

export function render(_ctx, _cache) {
  const _component_Header = _resolveComponent("Header")
  const _component_Carousel = _resolveComponent("Carousel")
  const _component_InterestAccess = _resolveComponent("InterestAccess")
  const _component_Faqs = _resolveComponent("Faqs")
  const _component_Location = _resolveComponent("Location")
  const _component_Footer = _resolveComponent("Footer")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Carousel)
    ]),
    _createVNode(_component_InterestAccess),
    _createVNode(_component_Faqs),
    _createVNode(_component_Location, { class: "locationBackground pb-6 md:pb-0 lg:pb-0" }),
    _createVNode(_component_Footer, { banner: false })
  ]))
}