
import { ClipboardListIcon, DownloadIcon, EyeIcon } from "@heroicons/vue/solid";
import { defineComponent } from "vue";
import { Options, Vue } from "vue-class-component";
import Footer from "../Reusables/Footer.vue";
import Header from "../Reusables/Header.vue";
import ModalImage from "../Reusables/ModalImage.vue";

@Options({
  components: {
    Header,
    Footer,
    ModalImage,
    DownloadIcon,
    EyeIcon,
    ClipboardListIcon,
    defineComponent,
  },
  name: "Files",
})
export default class Files extends Vue {
  public showModalImage = false;
  public imageOpen = "";
  public documents = [
    {
      title: "Licencia Construccion",
      description:
        "Documentos requeridos para solicitud de licencia de construccion",
      imageUrl: "../files/LicenciaConstruccion.png",
      aditionalDocs:
        "https://drive.google.com/file/d/1JLdyGlwY-jD1eVNOH2_cjsKXPZlN-_-c/view?usp=share_link",
    },
    {
      title: "Licencia Parcelacion",
      description:
        "Documentos requeridos para solicitud de licencia de parcelacion",
      imageUrl: "../files/LicenciaParcelacion.png",
      aditionalDocs:
        "https://drive.google.com/file/d/1P2_xGIQgv9_GaNOFk3wnSxFQFWLFEHrY/view?usp=share_link",
    },
    {
      title: "Licencia Subdivision",
      description:
        "Documentos requeridos para solicitud de licencia de subdivision",
      imageUrl: "../files/LicenciaSubdivision.png",
      aditionalDocs:
        "https://drive.google.com/file/d/18xoqJnSCeQE0y3rWHjABfFN4wrd-SN_v/view?usp=share_link",
    },
    {
      title: "Licencia Urbanismo",
      description:
        "Documentos requeridos para solicitud de licencia de urbanizacion",
      imageUrl: "../files/LicenciaUrbanismo.png",
      aditionalDocs:
        "https://drive.google.com/file/d/1unYV0vipmU0zuGoG6ebE5qDi67KowWad/view?usp=share_link",
    },
    {
      title: "Ajuste de Cotas y Areas",
      description:
        "Documentos requeridos para solicitud de ajuste de cotas y areas",
      imageUrl: "../files/AjusteCotasAreas.png",
    },
    {
      title: "Correccion de texto",
      description:
        "Documentos requeridos para solicitud de correccion de texto",
      imageUrl: "../files/LicenciaParcelacion.png",
    },
    {
      title: "Movimiento de tierras",
      description:
        "Documentos requeridos para solicitud de movimiento de tierras",
      imageUrl: "../files/MovimientoTierras.png",
    },
    {
      title: "Norma urbanistica",
      description: "Documentos requeridos para solicitud de norma urbanistica",
      imageUrl: "../files/NormaUrbanistica.png",
    },
    {
      title: "Propiedad horizontal",
      description:
        "Documentos requeridos para solicitud de propiedad horizontal",
      imageUrl: "../files/PropiedadHorizontal.png",
    },
    {
      title: "Prorroga de licencia",
      description:
        "Documentos requeridos para solicitud de prorroga de licencia",
      imageUrl: "../files/ProrrogaLicencia.png",
    },
    {
      title: "Reconocimiento de edificaciones",
      description:
        "Documentos requeridos para solicitud de reconocimiento de edificaciones",
      imageUrl: "../files/ReconocimientoEdificaciones.png",
      aditionalDocs:
        "https://drive.google.com/file/d/1rBeiV5eCFcyRX6eFLiY50SGooTnscToj/view?usp=share_link",
    },
    {
      title: "Uso del suelo",
      description: "Documentos requeridos para solicitud de uso de suelo",
      imageUrl: "../files/UsoSuelo.png",
    },
  ];

  showModal(url: string) {
    this.showModalImage = true;
    this.imageOpen = url;
  }

  closeModal() {
    this.showModalImage = false;
  }

  downloadItem() {
    const image = document.getElementById("imageForDownload");
    if (image != null) image.click();
  }
}
