import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import OpenLayersMap from "vue3-openlayers";
import "vue3-openlayers/dist/vue3-openlayers.css";
import { VueReCaptcha } from "vue-recaptcha-v3";

import "./styles/app.css"; // Here
import i18n from "./i18n";

createApp(App)
  .use(i18n)
  .use(router)
  .use(OpenLayersMap)
  .use(VueReCaptcha, { siteKey: "6Lf8e7cgAAAAANCsGR75i8OBlkJD6rg7Rurthywp", loaderOptions: {
    autoHideBadge: true,
  }})
  .mount("#app");
