import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CarouselItem = _resolveComponent("CarouselItem")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createBlock(_component_swiper, {
    effect: 'coverflow',
    spaceBetween: 30,
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    pagination: {
      type: 'progressbar',
      clickable: true,
    },
    autoplay: {
      delay: 4500,
      disableOnInteraction: false,
    },
    navigation: true,
    modules: _ctx.modules,
    class: "flex h-400"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_swiper_slide, null, {
        default: _withCtx(() => [
          _createVNode(_component_CarouselItem, { path: "Monteria1" })
        ]),
        _: 1
      }),
      _createVNode(_component_swiper_slide, null, {
        default: _withCtx(() => [
          _createVNode(_component_CarouselItem, { path: "Monteria2" })
        ]),
        _: 1
      }),
      _createVNode(_component_swiper_slide, null, {
        default: _withCtx(() => [
          _createVNode(_component_CarouselItem, { path: "Monteria3" })
        ]),
        _: 1
      }),
      _createVNode(_component_swiper_slide, null, {
        default: _withCtx(() => [
          _createVNode(_component_CarouselItem, { path: "Monteria4" })
        ]),
        _: 1
      }),
      _createVNode(_component_swiper_slide, null, {
        default: _withCtx(() => [
          _createVNode(_component_CarouselItem, { path: "Monteria5" })
        ]),
        _: 1
      }),
      _createVNode(_component_swiper_slide, null, {
        default: _withCtx(() => [
          _createVNode(_component_CarouselItem, { path: "Monteria6" })
        ]),
        _: 1
      }),
      _createVNode(_component_swiper_slide, null, {
        default: _withCtx(() => [
          _createVNode(_component_CarouselItem, { path: "Monteria7" })
        ]),
        _: 1
      }),
      _createVNode(_component_swiper_slide, null, {
        default: _withCtx(() => [
          _createVNode(_component_CarouselItem, { path: "Monteria8" })
        ]),
        _: 1
      }),
      _createVNode(_component_swiper_slide, null, {
        default: _withCtx(() => [
          _createVNode(_component_CarouselItem, { path: "Monteria9" })
        ]),
        _: 1
      }),
      _createVNode(_component_swiper_slide, null, {
        default: _withCtx(() => [
          _createVNode(_component_CarouselItem, { path: "Monteria10" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modules"]))
}