<template>
  <div class="fixed inset-x-0 bottom-0 z-50 bg-curaduria">
    <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div class="pr-16 sm:text-center sm:px-16">
        <p class="font-medium text-white">
          <span class="md:hidden"> {{ $t("uniqueForm.small") }} </span>
          <span class="hidden md:inline"> {{ $t("uniqueForm.big") }} </span>
          <span class="block sm:ml-2 sm:inline-block">
            <a href="https://drive.google.com/file/d/15atUVl_mSzbqP1lqlbD10gXVSyeKx1hV/view" target="_blank"
              class="text-white font-bold underline"> {{ $t("uniqueForm.btnDownload") }} <span
                aria-hidden="true">&rarr;</span></a>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts"></script>