export default {
  "carouselText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curaduria #2 Monteria"])},
  "buttonContactCarousel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactanos"])},
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentos"])},
    "consultas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultas"])},
    "resoluciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicaciones"])},
    "transparencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparencia"])},
    "normatividad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normatividad"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])},
    "us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosotros"])}
  },
  "licences": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licencias"])}
  },
  "location": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuéntranos"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cl 24 2-43 L-1 Centro / Montería, Córdoba"])}
  },
  "faqs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas frecuentes"])}
  },
  "footer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlaces de interés"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactanos"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curaduria Urbana Segunda de Montería"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cl 24 2-43 L-1 Centro / Montería, Córdoba"])},
    "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(604)7835093"])},
    "cellphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+57 3126102892"])}
  },
  "content": {
    "titleSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesos de interés"])},
    "desSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En esta sección encontrarás accesos directos a información relevante"])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulario único nacional"])},
    "des1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes consultar y descargar el formato único nacional actualizado desde aquí"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta de publicaciones"])},
    "des2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrás consultar tus publicaciones separadas por año desde aquí"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requisitos para trámites"])},
    "des3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta aquí los requisitos que necesitas para tus trámites"])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normatividad"])},
    "des4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conoce toda la información solicitada por el decreto 1077 del 2015"])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de nosotros"])},
    "des5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si quieres saber más acerca de nosotros y nuestro trabajo, accede aquí"])},
    "title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparencia"])},
    "des6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta aquí información importante"])}
  },
  "us": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosotros"])},
    "des": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquí encontrarás información de la organizacion y funcionamiento de nuestra Curaduria Urbana Segunda de Montería"])},
    "mision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misión"])},
    "desMision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atender los requerimientos de los ciudadanos brindando una orientación clara y concisa que les facilite el cumplimiento de la normatividad urbana para el otorgamiento de una Licencia Urbanística."])},
    "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visión"])},
    "desVision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantener nuestra imagen de credibilidad a través de la mejora y actualización de los procesos de expedición de licencias atendiendo eficientemente los requerimientos de los usuarios."])},
    "politica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política"])},
    "desPolitica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es compromiso de la Curaduría, la mejora de sus procesos manteniendo su imagen de credibilidad, con el fin de atender los requerimientos de los usuarios en forma eficiente y pertinente en la expedición de licencias urbanísticas."])},
    "funciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funciones y Deberes"])},
    "desFunciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El curador urbano es un particular encargado de estudiar, tramitar y expedir licencias de parcelación, urbanismo, construcción o demolición, y para el loteo o subdivisión de predios, a petición del interesado en adelantar proyectos de parcelación, urbanización, edificación, demolición o de loteo o subdivisión de predios, en las zonas o áreas del municipio o distrito que la administración municipal o distrital le haya determinado como de su jurisdicción. La curaduría urbana implica el ejercicio de una función pública para la verificación del cumplimiento de las normas urbanísticas y de edificación vigentes, a través del otorgamiento de licencias de urbanización y de construcción."])},
    "procesos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesos y Procedimientos"])},
    "organigrama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organigrama"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestro equipo"])}
  },
  "documents": {
    "buttonSee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver"])},
    "buttonDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentación"])},
    "des": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquí encontrarás información de la documentación requerida para cada uno de los procedimientos que requieras"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curaduría Urbana Segunda de Montería"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arq. Lila María Esquivel Rubio"])},
    "buttonHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comunícate con nosotros"])},
    "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importante"])},
    "badgeDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentación"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctenos"])},
    "des": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquí encontrarás información de contacto, y tambien podrás enviarnos un mensaje"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calle 24 No. 2-43 local 1 / Monteria-Cordoba"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curaduría Urbana Segunda de Montería"])},
    "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(604)7835093"])},
    "cellphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+57 3126102892"])},
    "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulario de contacto"])},
    "formDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor completa esta información para contactarte con nosotros"])},
    "nameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "lastNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "emailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo"])},
    "emailNotLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo para notificaciones judiciales"])},
    "messageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje"])},
    "addressLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "telephoneLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "cellphoneLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celular"])},
    "sendBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "googleDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este sitio esta protegido por reCAPTCHA y las"])},
    "googlePrivacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politicas de privacidad"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y"])},
    "googleTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminos de servicio"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de Google aplican"])},
    "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horario de atención"])},
    "scheduleFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horario de atención:"])},
    "scheduleHor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunes a viernes de 7am a 12 - 1pm a 4pm"])}
  },
  "transparency": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparencia"])},
    "des": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cumplimiento a la Ley 1712 de 2014, el Decreto 103 de 2015 y la Resolución 3564 de 2015 de MINTIC, La Curadora Urbana Segunda de Montería pone a disposición de la ciudadanía la siguiente información:"])},
    "locationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubicación y horario de Atención"])},
    "locationDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calle 24 No. 2-43 local 1 Lat: 8.7542 Lon: -75.8883"])},
    "scheduleTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horario de Atención"])},
    "scheduleDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunes a viernes de 7:00 a.m. a 4:00 p.m."])},
    "emailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "emailNotTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo para notificaciones judiciales"])},
    "addressTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "addressDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calle 24 No. 2-43 local 1"])},
    "telTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono fijo"])},
    "telDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["57+ 7895392"])},
    "celTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celular"])},
    "celDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+57 3126102892"])},
    "faxTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
    "faxDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["57+ 7895392"])},
    "emailInstTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo institucional"])},
    "emailPhisTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo físico o postal"])},
    "emailPhisDes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calle 24 No. 2-43 Local 1"])},
    "termsPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politica de tratamiento de la información"])},
    "transactionalFlow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flujograma de trámites"])},
    "navLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga click aquí"])},
    "rates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifas 2023"])}
  },
  "resolutions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicaciones"])},
    "des": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquí encontrarás las publicaciones ordenadas en carpetas por año de creación"])},
    "btnGoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a la carpeta"])},
    "res2019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicaciones expedidas en 2019"])},
    "res2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicaciones expedidas en 2020"])},
    "res2021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicaciones expedidas en 2021"])},
    "res2022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicaciones expedidas en 2022"])},
    "res2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicaciones expedidas en 2023"])},
    "res2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicaciones expedidas en 2024"])},
    "denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negadas"])},
    "otherAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otras actuaciones"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otros"])},
    "othersFallo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juzgado sexto administrativo oral del circuito de Montería"])},
    "othersDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otras publicaciones"])}
  },
  "uniqueForm": {
    "big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarga el formulario unico nacional"])},
    "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulario unico nacional"])},
    "btnDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar"])}
  },
  "normativity": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normatividad"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidades de planeamiento"])},
    "des": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjuntamos toda la información necesaria para la ciudadanía"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conoce más"])},
    "decreto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decreto 1077 del 2015"])},
    "pot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POT Montería"])},
    "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normas y decretos"])},
    "oriental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Borde oriental"])},
    "buttonSee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver"])},
    "buttonDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar"])},
    "occidental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Borde occidental"])},
    "sur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Ciudad sur"])},
    "centro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Ciudad centro"])},
    "norte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Ciudad norte"])}
  },
  "notFound": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 error"])},
    "des": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página no encontrada."])},
    "des1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, nosotros no podemos encontrar la página que estas buscando."])},
    "goHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver al inicio"])}
  }
}