
import { Vue } from "vue-class-component";
import { Watch, Prop, Component } from "vue-property-decorator";
import { Images } from "../../utils/Images";
import "../../styles/app.css";
import { useRouter } from "vue-router";

export default class CarouselItem extends Vue {
  @Prop(String) public path: string | undefined;
  @Prop(String) public text: string | undefined;
  @Prop(String) public buttonPath: string | undefined;
  @Prop(String) public buttonText: string | undefined;
  public image = "";

  @Watch("path", { immediate: true, deep: true })
  public imageMenu() {
    switch (this.path) {
      case Object.getOwnPropertyNames(Images)[0]:
        this.image = Images.Monteria1;
        break;
      case Object.getOwnPropertyNames(Images)[1]:
        this.image = Images.Monteria2;
        break;
      case Object.getOwnPropertyNames(Images)[2]:
        this.image = Images.Monteria3;
        break;
      case Object.getOwnPropertyNames(Images)[3]:
        this.image = Images.Monteria4;
        break;
      case Object.getOwnPropertyNames(Images)[4]:
        this.image = Images.Monteria5;
        break;
      case Object.getOwnPropertyNames(Images)[5]:
        this.image = Images.Monteria6;
        break;
      case Object.getOwnPropertyNames(Images)[6]:
        this.image = Images.Monteria7;
        break;
      case Object.getOwnPropertyNames(Images)[7]:
        this.image = Images.Monteria8;
        break;
      case Object.getOwnPropertyNames(Images)[8]:
        this.image = Images.Monteria9;
        break;
      case Object.getOwnPropertyNames(Images)[9]:
        this.image = Images.Monteria10;
        break;
    }
  }
  public goToContact(){
    this.$router.push({ name: "contacto" });
  }
}
