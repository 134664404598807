
import Header from "../Reusables/Header.vue";
import Footer from "../Reusables/Footer.vue";
import ModalPdf from "../Reusables/ModalPdf.vue";
import { XIcon, EyeIcon, DownloadIcon } from '@heroicons/vue/outline'
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    Header,
    Footer,
    ModalPdf,
    EyeIcon,
    DownloadIcon,
    XIcon
  },
  name: 'Normativity',
})
export default class Normativity extends Vue {
  public showModalPdf = false;
  public pdfRoute = "";
  public bordeOriental = [
    {
      title: "Borde Oriental",
      description:
        "UDP510 -02 - SIERRA CHIQUITA",
      pdfUrl: "../pdf/oriental/BOR1.pdf",
    },
    {
      title: "Borde Oriental",
      description:
        "UDP520 -02 - LA PAZ - FURATENA ",
      pdfUrl: "../pdf/oriental/BOR2.pdf",
    },
    {
      title: "Borde Oriental",
      description:
        "UDP530 -02 - LA ESTANCIA ",
      pdfUrl: "../pdf/oriental/BOR3.pdf",
    },
    {
      title: "Borde Oriental",
      description:
        "UDP540 -02 - BONANZA - SORRENTO",
      pdfUrl: "../pdf/oriental/BOR4.pdf",
    },
    {
      title: "Borde Oriental",
      description:
        "UDP550 -02 - VILLA CIELO ",
      pdfUrl: "../pdf/oriental/BOR5.pdf",
    },
  ];
  public bordeOccidental = [
    {
      title: "Borde Occidental",
      description:
        "UDP410 -02 - JUAN XXIII - LAS VILLAS ",
      pdfUrl: "../pdf/occidental/BO1.pdf",
    },
    {
      title: "Borde Occidental",
      description:
        "UDP420 -02 - RANCHO GRANDE - EL DORADO",
      pdfUrl: "../pdf/occidental/BO2.pdf",
    },
  ];
  public ciudadSur = [
    {
      title: "Ciudad sur",
      description:
        "UDP310 -02 - COQUERA - BUENAVISTA ",
      pdfUrl: "../pdf/sur/310-02.pdf",
    },
    {
      title: "Ciudad sur",
      description:
        "UDP320 -02 - SANTA FE - SANTANDER",
      pdfUrl: "../pdf/sur/320-02.pdf",
    },
    {
      title: "Ciudad sur",
      description:
        "UDP340 -02 - MOGAMBO - GALILEA  ",
      pdfUrl: "../pdf/sur/340-02.pdf",
    },
    {
      title: "Ciudad sur",
      description:
        "UDP360 -02 - CANTA CLARO ",
      pdfUrl: "../pdf/sur/360-02.pdf",
    },
  ];
  public ciudadCentro = [
    {
      title: "Ciudad centro",
      description:
        "UDP210 -02 - SUCRE ",
      pdfUrl: "../pdf/centro/210-02.pdf",
    },
    {
      title: "Ciudad centro",
      description:
        "UDP220 -02 - ALAMEDAS - LAURELES",
      pdfUrl: "../pdf/centro/210-02.pdf",
    },
    {
      title: "Ciudad centro",
      description:
        "UDP230 -02 - VILLA OLÍMPICA - LOS ÁNGELES  ",
      pdfUrl: "../pdf/centro/230-02.pdf",
    },
    {
      title: "Ciudad centro",
      description:
        "UDP240 -02 - CENTRO TRADICIONAL",
      pdfUrl: "../pdf/centro/240-02.pdf",
    },
    {
      title: "Ciudad centro",
      description:
        "UDP250 -02 - CHUCHURUBÍ - LA CEIBA  ",
      pdfUrl: "../pdf/centro/250-02.pdf",
    },
    {
      title: "Ciudad centro",
      description:
        "UDP260 -02 - LA JULIA - OSPINA PÉREZ ",
      pdfUrl: "../pdf/centro/260-02.pdf",
    },
    {
      title: "Ciudad centro",
      description:
        "UDP270 -02 - LA FLORESTA - LA GALLERA",
      pdfUrl: "../pdf/centro/270-02.pdf",
    },
    {
      title: "Ciudad centro",
      description:
        "UDP280 -02 - CIUDAD CENTRAL  ",
      pdfUrl: "../pdf/centro/240-02.pdf",
    },
  ];
  public ciudadNorte = [
    {
      title: "Ciudad norte",
      description:
        "UDP110 -02 - MOCARÍ - LA U",
      pdfUrl: "../pdf/norte/110-02.pdf",
    },
    {
      title: "Ciudad norte",
      description:
        "UDP120 -02 - EL CEIBAL - NUEVO BOSQUE",
      pdfUrl: "../pdf/norte/120-02.pdf",
    },
    {
      title: "Ciudad norte",
      description:
        "UDP130 -02 - EL RECREO",
      pdfUrl: "../pdf/norte/130-02.pdf",
    },
    {
      title: "Ciudad norte",
      description:
        "UDP140 -01 - SEVILLA CAMPESTRE ",
      pdfUrl: "../pdf/norte/140-01.pdf",
    },
    {
      title: "Ciudad norte",
      description:
        "UDP140 -02 - SEVILLA CAMPESTRE ",
      pdfUrl: "../pdf/norte/140-02.pdf",
    },
  ];

  showModal(url: string) {
    this.showModalPdf = true;
    this.pdfRoute = url;
  }

  closeModal() {
    this.showModalPdf = false;
  }

  downloadItem() {
    const pdf = document.getElementById("pdfForDownload");
    if (pdf != null) pdf.click();
  }
}
