
import { defineComponent } from "vue";
import Footer from "../Reusables/Footer.vue";
import Header from "../Reusables/Header.vue";

export default defineComponent({
  components: {
    Header,
    Footer,
  },
  setup() {
    const supportLinks = [
      {
        name: "CURADORA URBANA SEGUNDA DE MONTERÍA LILA MARÍA ESQUIVEL RUBIO",
        paragraph: [
          "Arquitecta especialista en planificación y administración del desarrollo regional.",
          "Ha sido designada curadora urbana de Montería por dos (02) periodos consecutivos, de cinco (05) años cada uno, entre los años 2010 y 2020.",
          "Mediante el concurso de méritos 01/2018 del Departamento Administrativo de la Función Pública y la Superintendencia de Notariado y registro, fue designada curadora urbana segunda de Montería, según resolución 0425 de 2021, para un (01) periodo de cinco (05) años comprendido entre el 14  de julio de 2021 y el 13 de julio de 2026.",
        ],
      },
      {
        name: "ABOGADA ESPECIALISTA TATIANA ARÉVALO URIBE",
        paragraph: [
          "Abogada especialista en Derecho Urbano.",
          "Experiencia profesional de más de diez (10) años en el ejercicio de su profesión revisando Planes de Ordenamiento Territorial, estructuración de instrumentos normativos de Planificación y asesoría para la Gestión Urbana.",
        ],
      },
      {
        name: "ARQUITECTO ESPECIALISTA VIVIANA GÓMEZ MARTÍNEZ",
        paragraph: [
          "Arquitecta especialista en derecho urbano.",
          "Experiencia profesional de más de cinco (5) años en el ejercicio de su profesión revisando y aprobando los expedientes de las licencias urbanísticas de la curaduría urbana segunda de montería.",
        ],
      },
      {
        name: "INGENIERO CIVIL ESPECIALISTA NICOLÁS PEINADO",
        paragraph: [
          "Ingeniero civil especialista en estructuras.",
          "Experiencia profesional de más de cinco (5) años en el ejercicio de su profesión revisando y aprobando los expedientes de las licencias urbanísticas de la curaduría urbana segunda de montería.",
        ],
      },
    ];

    function openAnsSection(val: number) {
      var p = document.getElementById("para" + val);
      var svg = document.getElementById("path" + val);

      if (p?.classList.contains("hidden")) {
        p.classList.remove("hidden");
        p.classList.add("block");
      } else {
        p?.classList.remove("block");
        p?.classList.add("hidden");
      }

      if (svg?.classList.contains("hidden")) {
        svg.classList.remove("hidden");
        svg.classList.add("block");
      } else {
        svg?.classList.remove("block");
        svg?.classList.add("hidden");
      }
    }
    return {
      openAnsSection,
      supportLinks,
    };
  },
});
