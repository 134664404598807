import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed overflow-x-hidden overflow-y-auto max-h-3/4 max-w-2/4 inset-0 flex justify-left items-center z-50"
}
const _hoisted_2 = { class: "relative mx-auto max-h-3/4 max-w-2/4" }
const _hoisted_3 = { class: "bg-white max-h-3/4 max-w-2/4 rounded shadow-xl flex flex-col border-4 border-curaduria" }
const _hoisted_4 = { class: "flex w-full place-content-end" }
const _hoisted_5 = { class: "bg-white w-full px-4 py-3 rounded" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DownloadIcon = _resolveComponent("DownloadIcon")!
  const _component_XIcon = _resolveComponent("XIcon")!
  const _component_pdf = _resolveComponent("pdf")!

  return (_ctx.modalVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_DownloadIcon, {
                class: "h-8 w-8 flex w-full pr-2 text-curaduria cursor-pointer",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('download')))
              }),
              _createVNode(_component_XIcon, {
                class: "h-8 w-8 flex w-full pr-2 text-curaduria cursor-pointer",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closed')))
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_pdf, {
                src: _ctx.pdfUrl!,
                scale: 0.2,
                class: "h-full w-full object-fill rounded-sm"
              }, null, 8, ["src", "scale"])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}