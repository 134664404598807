
import { defineComponent } from "vue";
import Header from "../Reusables/Header.vue";
import Footer from "../Reusables/Footer.vue";
import { PaperClipIcon } from '@heroicons/vue/solid'

export default defineComponent({
  components: {
    Header, 
    Footer,
    PaperClipIcon
  },
});
