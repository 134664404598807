import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-7xl mx-auto px-4 sm:px-6" }
const _hoisted_2 = { class: "2xl:container 2xl:mx-auto md:py-12 lg:px-20 md:px-6 py-9 px-4" }
const _hoisted_3 = { class: "w-full flex flex-col justify-content-center items-center" }
const _hoisted_4 = { class: "font-semibold lg:text-4xl text-3xl lg:leading-9 md:leading-7 leading-9 text-curaduria" }
const _hoisted_5 = {
  role: "list",
  class: "grid sm:grid-cols-2 grid-cols-1 gap-6 mt-6 auto-rows-auto items-start"
}

export function render(_ctx, _cache) {
  const _component_Header = _resolveComponent("Header")
  const _component_Resolution = _resolveComponent("Resolution")
  const _component_Footer = _resolveComponent("Footer")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t("resolutions.others")), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("ul", _hoisted_5, [
            _createVNode(_component_Resolution, {
              title: _ctx.$t('resolutions.othersFallo'),
              description_large: 
                    ('En atención al fallo No. del Juzgado Sexto Administrativo Oral del Circuito de Montería, se procede a publicar la decisión por el cual se ampara el derecho colectivo a la realización de las construcciones, edificaciones y desarrollos urbanos respetando las disposiciones jurídicas, de manera ordenada, y dando prevalencia al beneficio de la calidad de vida de los habitantes (literal m) del artículo 4º de la Ley 472 de 1998), y otras disposiciones:'),
              navigateTo: 'https://drive.google.com/file/d/1xiSBr8BpHA8sIyvptz0g1FiGapVhE-_D/view',
              img: '../files/PublicacionFallo.png'
            }, null, 8, ["title", "description_large", "navigateTo", "img"]),
            _createVNode(_component_Resolution, {
              title: _ctx.$t('resolutions.othersDefault'),
              navigateTo: 'https://drive.google.com/drive/folders/1-GorMrj8FhoHh-mkpmoW6zT4fqvrSpdV'
            }, null, 8, ["title", "navigateTo"])
          ])
        ])
      ])
    ]),
    _createVNode(_component_Footer)
  ], 64))
}