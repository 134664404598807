
import { defineComponent } from "vue";
import pdf from 'pdfvuer'
import { XIcon, DownloadIcon } from "@heroicons/vue/outline";

export default defineComponent({
  components: {
    XIcon,
    DownloadIcon,
    pdf
  },
  props: {
    modalVisible: Boolean,
    pdfUrl: String,
  },
});
