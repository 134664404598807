
import Header from "../Reusables/Header.vue";
import Footer from "../Reusables/Footer.vue";
import Resolution from "./Resolution.vue";

import { defineComponent } from "vue";

export default defineComponent({
  components: {
    Header,
    Footer,
    Resolution
  }
});

