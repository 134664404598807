import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "./components/Home/Home.vue";
import Us from "./components/Us/Us.vue";
import Contact from "./components/Contact/Contact.vue";
import Files from "./components/Files/Files.vue";
import Transparency from "./components/Transparency/Transparency.vue";
import Resolutions from "./components/Resolutions/Resolutions.vue";
import ResolutionOthers from "./components/Resolutions/Others/ResolutionsOthers.vue";
import Normativity from "./components/Normativity/Normativity.vue";
import NotFound from "./components/Reusables/NotFound.vue";
import Licencias from "./components/Licences/Licences.vue";
import Inquiries from "./components/Inquiries/Inquiries.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/nosotros",
    name: "nosotros",
    component: Us,
  },
  {
    path: "/contacto",
    name: "contacto",
    component: Contact,
  },
  {
    path: "/documentos",
    name: "documentos",
    component: Files,
  },
  {
    path: "/transparencia",
    name: "transparencia",
    component: Transparency,
  },
  {
    path: "/resoluciones",
    name: "resoluciones",
    component: Resolutions
  },
  {
    path: "/otrasResoluciones",
    name: "otrasResoluciones",
    component: ResolutionOthers
  },
  {
    path: "/normatividad",
    name: "normatividad",
    component: Normativity
  },
  {
    path: "/licencias",
    name: "licencias",
    component: Licencias,
  },
  {
    path: "/consultas",
    name: "consultas",
    component: Inquiries,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
