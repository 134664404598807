import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full bg-black" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "-10 pl-10 md:pt-8" }
const _hoisted_4 = { class: "centered font-medium decoration-double decoration-curaduria" }
const _hoisted_5 = { class: "my-4 text-lg font-bold leading-tight w-full text-centers sm:text-4xl" }
const _hoisted_6 = { class: "leading-normal text-base mb-8 w-full text-center sm:text-2xl" }
const _hoisted_7 = { class: "justify-center text-center mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.image,
      class: "opacity-70"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t("home.title")), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("home.title2")), 1),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToContact && _ctx.goToContact(...args))),
            class: "mx-10px text-sm sm:text-xl lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-md my-1 py-4 px-6 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
          }, _toDisplayString(_ctx.$t("home.buttonHome")), 1)
        ])
      ])
    ])
  ]))
}