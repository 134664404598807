<template>
  <div class="h-full">
    <div class="min-h-full pt-16 pb-12 flex flex-col bg-white">
      <main class="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex-shrink-0 flex justify-center">
          <a href="/" class="inline-flex mt-20">
            <img class="h-20 w-auto" src="../../assets/logoCuraduria.jpg" alt="" />
          </a>
        </div>
        <div class="py-16">
          <div class="text-center">
            <p class="text-2xl font-semibold text-curaduria uppercase tracking-wide"> {{ $t("notFound.title") }}</p>
            <h1 class="mt-2 text-4xl font-extrabold text-curaduria tracking-tight sm:text-5xl"> {{ $t("notFound.des") }}
            </h1>
            <p class="mt-2 text-base">{{ $t("notFound.des1") }}</p>
            <div class="mt-6">
              <a href="/" class="text-base font-medium text-curaduria hover:text-gray-500">{{ $t("notFound.goHome")
              }}<span aria-hidden="true"> &rarr;</span></a>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script lang="ts"></script>