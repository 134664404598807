
import Alert from "../Reusables/Alert.vue";
import Footer from "../Reusables/Footer.vue";
import Header from "../Reusables/Header.vue";
import { defineComponent, ref } from "vue";
import "../../styles/app.css";

export default defineComponent({
    components: {
    Header,
    Footer,
    Alert,
    }
});
