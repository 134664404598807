
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";
import Carousel from "../Reusables/Carousel.vue";
import Location from "../Location/Location.vue";
import Faqs from "../Faqs/Faqs.vue";
import Header from "../Reusables/Header.vue";
import Footer from "../Reusables/Footer.vue";
import InterestAccess from "../Reusables/InterestAccess.vue";
import {
  FolderIcon,
  HomeIcon,
  UserCircleIcon,
  DocumentTextIcon,
  BookOpenIcon,
  UsersIcon,
  ClipboardListIcon,
} from "@heroicons/vue/solid";
import { defineComponent } from "vue";
import "../../styles/app.css";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    FolderIcon,
    HomeIcon,
    UserCircleIcon,
    MenuIcon,
    BookOpenIcon,
    DocumentTextIcon,
    ClipboardListIcon,
    XIcon,
    UsersIcon,
    Carousel,
    Location,
    Header,
    Footer,
    InterestAccess,
    Faqs,
  }
});
