
import { Swiper, SwiperSlide } from "swiper/vue";
import CarouselItem from "../Reusables/CarouselItem.vue";
import {
  EffectCube,
  Pagination,
  Autoplay,
  EffectCards,
  EffectFade,
  EffectFlip,
  EffectCoverflow,
} from "swiper";
import { defineComponent } from "vue";

import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/effect-cards";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "../../styles/swipper.css";

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    CarouselItem,
  },
  setup() {
    const modules = [
      Pagination,
      EffectCube,
      Autoplay,
      EffectCards,
      EffectFade,
      EffectFlip,
      EffectCoverflow,
    ];
    return {
      modules,
    };
  },
});
