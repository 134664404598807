export const Images = {
  Monteria1: "../../images/Monteria1.jpg",
  Monteria2: "../../images/Monteria2.jpg",
  Monteria3: "../../images/Monteria3.jpg",
  Monteria4: "../../images/Monteria4.jpg",
  Monteria5: "../../images/Monteria5.jpg",
  Monteria6: "../../images/Monteria6.jpg",
  Monteria7: "../../images/Monteria7.jpg",
  Monteria8: "../../images/Monteria8.jpg",
  Monteria9: "../../images/Monteria9.jpg",
  Monteria10: "../../images/Monteria10.jpg"
};
