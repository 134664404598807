
import { defineComponent } from "vue";
import { XIcon, DownloadIcon } from "@heroicons/vue/outline";

export default defineComponent({
  components: {
    XIcon,
    DownloadIcon
  },
  props: {
    modalVisible: Boolean,
    imageUrl: String,
  },
});
