import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full container flex flex-col i mx-auto" }
const _hoisted_2 = { class: "bg-white text-left rounded shadow-md" }
const _hoisted_3 = { class: "mx-auto px-4 sm:py-24 sm:px-6 lg:px-8" }
const _hoisted_4 = { class: "flex justify-center font-bold text-4xl text-curaduria" }
const _hoisted_5 = { class: "mt-12" }
const _hoisted_6 = { class: "space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 md:space-y-0 lg:grid-cols-3" }
const _hoisted_7 = { class: "text-lg font-medium leading-6 text-gray-900" }
const _hoisted_8 = {
  key: 0,
  class: "mt-2 text-base text-gray-500 text-justify"
}
const _hoisted_9 = {
  key: 1,
  class: "mt-2 ml-4 text-base text-gray-500"
}
const _hoisted_10 = { class: "list-disc" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("faqs.title")), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("dl", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faqs, (faq) => {
              return (_openBlock(), _createElementBlock("div", {
                key: faq.id
              }, [
                _createElementVNode("dt", _hoisted_7, _toDisplayString(faq.question), 1),
                (faq.answer)
                  ? (_openBlock(), _createElementBlock("dd", _hoisted_8, _toDisplayString(faq.answer), 1))
                  : _createCommentVNode("", true),
                (faq.answers)
                  ? (_openBlock(), _createElementBlock("dd", _hoisted_9, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(faq.answers, (ans) => {
                        return (_openBlock(), _createElementBlock("ul", _hoisted_10, [
                          _createElementVNode("li", null, _toDisplayString(ans), 1)
                        ]))
                      }), 256))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}