
import { defineComponent } from "vue";

type Faq = {
  id: number;
  question: string;
  answer?: string;
  answers?: string[];
};

const faqs: Faq[] = [
  {
    id: 1,
    question: "¿Quién es un Curador Urbano?",
    answer:
      "Es un particular que ejerce la función pública de estudiar, tramitar y expedir licencias urbanísticas a petición del interesado en adelantar proyectos de urbanización, parcelación, construcción y subdivisión.",
  },
  {
    id: 2,
    question: "¿Qué es una Curaduría Urbana?",
    answer:
      "Es la oficina del Curador Urbano, en la cual, con apoyo del grupo interdisciplinario especializado, prestan los servicios de estudio, trámite y expedición de licencias urbanísticas.",
  },
  {
    id: 3,
    question: "¿Cuál es la naturaleza jurídica del Curador Urbano? ",
    answer:
      "      En cuanto a la naturaleza jurídica de los Curadores Urbanos, la Ley 388 de 1997 y el Decreto 1077 de 2015 les otorga la calidad de particulares que ejercen una función pública.",
  },
  {
    id: 10,
    question:
      "¿Quién es la instancia encargada de vigilar y controlar el cumplimiento de las normas urbanísticas y de los planes de ordenamiento territorial? ",
    answer:
      "De acuerdo con lo establecido en el numeral 7 del artículo 101 de la Ley 388 de 1997, el alcalde municipal, indelegablemente, será la instancia encargada de vigilar y controlar el cumplimiento de las normas urbanísticas y de los planes de ordenamiento territorial.",
  },
  {
    id: 5,
    question:
      "¿Qué tipo de licencias urbanísticas estudian, tramitan y expiden los Curadores Urbanos?",
    answer:
      "Los Curadores Urbanos expiden las siguientes licencias urbanísticas, en diferentes modalidades:",
    answers: [
      "Licencia de Urbanización",
      "Licencia de Parcelación",
      "Licencias de Subdivisión",
      "Licencias de Construcción",
    ],
  },
  {
    id: 9,
    question: "¿Toda obra requiere de licencia de construcción? ",
    answer:
      "Toda obra que tenga por objeto hacer viable una estructura para cualquiera de los usos o grupos de usos previstos en las normas vigentes, alterar su diseño estructural o lograr un incremento del área construida requiere de la respectiva licencia de construcción.",
  },
  {
    id: 6,
    question: "Qué otros trámites se adelantan ante los Curadores Urbanos?",
    answers: [
      "Reconocimiento de las edificaciones existentes",
      "Prorrogas de licencias",
      "Revalidaciones de licencias",
      "Ajuste de cotas de áreas",
      "Concepto de norma urbanística",
      "Concepto de uso del suelo",
      "Copia certificada de planos",
      "Aprobación de los Planos de Propiedad Horizontal",
      "Autorización para el movimiento de tierras",
      "Aprobación de piscinas",
      "Modificación de Planos Urbanísticos de legalización y demás planos que aprobaron desarrollos o asentamientos.",
    ],
  },
  {
    id: 8,
    question: "¿Qué costos deben pagarse al Curador Urbano?",
    answer:
      "Las solicitudes de licencias presentadas ante los curadores generan un cargo fijo, que debe ser cancelado al momento de la radicación de la solicitud, y un cargo variable, que se genera con la viabilidad del proyecto y que debe ser cancelado para la expedición de la correspondiente licencia. Los cargos fijos y variables, tienen unos factores distintos para cada municipio o distrito, igualmente su liquidación depende del uso, el área del proyecto, el estrato.Las licencias de subdivisión, la prórroga y revalidación de la licencia, como las otras actuaciones también generan el pago de un cargo único.",
  },
  {
    id: 11,
    question:
      "¿Quién es la instancia encargada de vigilar y controlar el cumplimiento de las normas urbanísticas y de los planes de ordenamiento territorial? ",
    answer:
      "En los términos del Decreto reglamentario 1077 de 2015 la competencia para la expedición de licencias para todo tipo de intervención y ocupación del espacio público, es exclusiva de las oficinas de planeación municipal.",
  },
  {
    id: 4,
    question: "¿Qué servicios NO prestan los Curadores Urbanos?",
    answers: [
      "Control y verificación de las obras",
      "Contravención a las normas de usos del suelo",
      "Elaboración de estudios y planes, Visitas a obras",
    ],
  },
  {
    id: 7,
    question: "¿Qué norma reglamenta el procedimiento de licenciamiento?",
    answer: "Actualmente el Decreto Nacional 1077 de 2015.",
  },
];

export default defineComponent({
  setup() {
    return {
      faqs,
    };
  },
});
