
import "../../styles/app.css";
import { ref, defineComponent } from "vue";

export default defineComponent({
  setup() {
    const center = [-75.8883059316684, 8.754624793541796];
    const coordinate = [-75.8883059316684, 8.754624793541796];
    const projection = ref("EPSG:4326");
    const zoom = ref(16);
    const rotation = ref(0);
    const radius = ref(40);

    return {
      center,
      projection,
      rotation,
      coordinate,
      zoom,
      radius,
      fullscreencontrol: true,
      attributioncontrol: true,
      zoomcontrol: true,
      zoomslidercontrol: true,
      scalelinecontrol: true,
      overviewmapcontrol: true,
      mousepositioncontrol: true,
      rotatecontrol: true,
    };
  },
});
