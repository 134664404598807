
import Alert from "../Reusables/Alert.vue";
import Footer from "../Reusables/Footer.vue";
import Header from "../Reusables/Header.vue";

import { isEmail } from "@/utils/Validators";
import { defineComponent, ref } from "vue";
// import { useReCaptcha } from "vue-recaptcha-v3";

export default defineComponent({
  components: {
    Header,
    Footer,
    Alert,
  },
  setup() {
    const name = ref("");
    const lastName = ref("");
    const email = ref("");
    const message = ref("");

    const informativeMessage = ref("");

    // const reCatpcha = useReCaptcha();

    const validateDataBeforeSend = (): void => {
      if (!isEmail(email.value)) {
        informativeMessage.value =
          "No se puede enviar un correo a este address. Por favor ingrese un email valido.";
        return;
      }
      if (!name.value || !lastName.value || !message.value) {
        informativeMessage.value = "Por favor complete todos los campos.";
        return;
      }
      informativeMessage.value = "";
      reCatpchaCheck();
    };

    const reCatpchaCheck = async () => {
      // if (!reCatpcha) return;
      // await reCatpcha.recaptchaLoaded();
      // const token = await reCatpcha.executeRecaptcha('submit')

      // TODO: Implement the network request to validate the catcha token
      // TODO: Change to use our own mailing system instead of mailto

      const composedBody = `
				Yo, ${name.value} ${lastName.value} (${email.value})%0A%0A
				${message.value}
			`;
      const composedSubject =
        "Curaduria%20Segunda%20Monteria%20-%20Contacto%20desde%20pagina%20web";
      const completeUrl = new URL(
        `mailto:info@curaduria2monteria.com?subject=${composedSubject}&body=${composedBody}`
      );

      window.location.href = completeUrl.href;
    };

    return {
      name,
      lastName,
      email,
      message,
      informativeMessage,
      validateDataBeforeSend,
      reCatpchaCheck,
    };
  },
});
